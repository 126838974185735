<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <div class="py-1">
            <v-avatar tile size="200">
              <img
                src="@/assets/images/illustrations/posting_photo.svg"
                alt=""
              />
            </v-avatar>
            <h5 class="font-weight-bold">LETS GET STARTED!</h5>
            <p class="text--disabled font-weight-medium">
              Create an account to POS now
            </p>
          </div>
          <!-- <h6 class="text--disabled font-weight-medium mb-10">
                        Sign in to your account
                    </h6> -->

          <v-text-field
            label="Your name"
            v-model="name"
            :error-messages="nameErrors"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          />
          <v-text-field
            label="Email"
            v-model="email"
            :error-messages="emailErrors"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          />

          <v-text-field
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            name="input-10-2"
            label="Password"
            v-model="password"
            @click:append="show = !show"
            :error-messages="passwordErrors"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          ></v-text-field>

          <v-text-field
            label="Business Name"
            v-model="bName"
            :error-messages="bNameErrors"
            required
            @input="$v.bName.$touch()"
            @blur="$v.bName.$touch()"
          />
          <v-select
            v-model="selectedPlan"
            :items="items"
            label="Subscription Plan"
            :item-text="getFieldText"
            return-object
            :error-messages="selectedPlanErrors"
            required
            @input="$v.selectedPlan.$touch()"
            @blur="$v.selectedPlan.$touch()"
          ></v-select>

          <v-checkbox
            v-model="checkbox"
            :error-messages="checkboxErrors"
            required
            @input="$v.checkbox.$touch()"
            @blur="$v.checkbox.$touch()"
          >
            <template v-slot:label>
              <div>
                I agree with
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      href="https://channelsoft.com.my/privacy-policy/"
                      target="_blank"
                      @click.stop
                      v-on="on"
                    >
                      terms and conditions
                    </a>
                  </template>
                  Opens in new window
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>

          <v-btn class="mb-4" block @click="register" color="primary"
            >Sign Up</v-btn
          >
          <div class="">
            Already have an account ?
            <v-btn text small color="primary" to="/app/sessions/login"
              >Sign In</v-btn
            >
          </div>
        </v-card-text>

        <!-- error password snackbar  -->
        <v-snackbar v-model="snackbar" top :color="sColor">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="" text v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
<script>
import { BASEURL } from "@/api/baseurl";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "signup",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Register",
  },
  mixins: [validationMixin],

  validations: {
    name: { required },
    email: {
      required,
      email,
      exist(value) {
        if (value === "") return true;
        return new Promise((resolve, reject) => {
          const params = new URLSearchParams();
          params.append("checkEmailExist", "done");
          params.append("email", this.email);
          axios({
            method: "post",
            url: this.domain + "/user/index.php",
            data: params,
          })
            .then((response) => {
              value = 2;
              const email = response.data.status;
              resolve(value === email);
            })
            .catch(() => reject(false));
        });
      },
    },
    password: { required, minLength: minLength(6) },
    bName: { required },
    selectedPlan: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data() {
    return {
      domain: BASEURL,
      show: false,
      checkbox: false,
      email: "",
      message: "",
      password: "",
      name: "",
      bName: "",
      loading: false,
      snackbar: false,
      sColor: "",
      emailExist: "",
      items: [],
      selectedPlan: "",
    };
  },

  created() {
    this.getAllSubscriptionPlan();
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid email");
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.exist && errors.push("Email already exists");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required.");
      !this.$v.password.minLength &&
        errors.push("Password must at least 6 character.");
      return errors;
    },

    bNameErrors() {
      const errors = [];
      if (!this.$v.bName.$dirty) return errors;
      !this.$v.bName.required && errors.push("Business Name is required.");
      return errors;
    },

    selectedPlanErrors() {
      const errors = [];
      if (!this.$v.selectedPlan.$dirty) return errors;
      !this.$v.selectedPlan.required && errors.push("Subscription Plan is required.");
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
  },

  watch: {
    email() {},
  },

  methods: {
    register() {
      var moment = require('moment');
      var startDate = moment().format("YYYY-MM-DD");
      var endDate = moment(startDate).add(this.selectedPlan.duration, 'M').format("YYYY-MM-DD");
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const params = new URLSearchParams();
        params.append("company_register", "done");
        params.append("user_name", this.name);
        params.append("email", this.email);
        params.append("password", this.password);
        params.append("company_name", this.bName);
        params.append("subscription_plan_id", this.selectedPlan.id);
        params.append("subscription_package", this.selectedPlan.subscription_package);
        params.append("subscription_fee", this.selectedPlan.subscription_fee);
        params.append("duration", this.selectedPlan.duration);
        params.append("branch_amount", this.selectedPlan.branch_amount);
        params.append("start_date", startDate);
        params.append("end_date", endDate);
        axios({
          method: "post",
          url: this.domain + "/company/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.snackbar = true;
              this.sColor = "success";
              this.message = "Successfully register account. wait for login...";
              this.login();
            } else {
              this.snackbar = true;
              this.sColor = "danger";
              this.message = "Something went wrong. Please try again";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    getAllSubscriptionPlan() {
      const params = new URLSearchParams();
      params.append("readAllSubscription", "done");
      axios({
        method: "post",
        url: this.domain + "/subscription/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.items = response.data.subscription;
          } else {
            this.items = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFieldText(item) {
      return `${item.subscription_package} - RM${item.subscription_fee} - ${item.duration} months - ${item.branch_amount} branch`;
    },
    login() {
      const params = new URLSearchParams();
      params.append("login", "done");
      params.append("email", this.email);
      params.append("password", this.password);

      axios({
        method: "post",
        url: this.domain + "/user/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var CryptoJS = require("crypto-js");

            // Encrypt
            var ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify(response.data.user),
              "secret key 123"
            ).toString();

            localStorage.setItem("userInfo", ciphertext);
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          } else {
            this.snackbar = true;
            this.sColor = "danger";
            this.message = "Auto login failed. Please sign in manually";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
